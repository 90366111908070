import '../styles/globals.css';
import Script from 'next/script';
import Head from 'next/head';

function MyApp({ Component, pageProps }) {
  return (
    <div>
      <Head>
        {/* Other head elements */}
      
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=GTM-WTFTSF4H`}
        strategy="lazyOnload"
      />
      <Script strategy="lazyOnload">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'GTM-WTFTSF4H');
        `}
      </Script></Head>
      <Component {...pageProps} />
    </div>
  );
}
export default MyApp;